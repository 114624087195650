#page-wrapper {
  padding-top: 20px;
  min-height: 800px;
}

.code14hidden {
  display: none;
}

a.add-button {
  float: right;
  position: relative;
  top: -40px;
}

h4.subtitle {
}

img#code14_logo_sidebar {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.alert {
  margin-bottom: 40px;
}

.navbar-default.navbar-static-side {
  background-color: #231f20;
  a {
    font-size: 18px;
    color: #fff;

    &:hover {
      background-color: #000;
      color: #4dbce9;
    }
  }
  i {
    font-size: 40px;
  }

  a.actice_sidebarpage {

    font-weight: bold;
  }
}

.delete_confirmation {
  display: none;
}

.hidden_alert {
  display: none;
}

div#personicon i {
  font-size: 80px;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

//#languageChooser a.active {
//  background-color: $hover-color;
//}

div#connected-page-panel {
  span.middot {
  }
  li {
    list-style-type: none;
    margin-left: -40px;

    //&:hover {
    //  background-color: $ hover-color;
    //}
  }
}

div.fasteditpopup {
  display: none;
}

img.page_component_contents_image {
  max-width: 300px;
  max-height: 200px;
}

// Niet nodig na nieuwe huisstijl
//.del_img_button {
//  color: red;
//  padding: 4px;
//  width: 42px;
//
//  text-align: center;
//  border: 4px solid red;
//  display: block;
//  margin-top: 10px;
//  -webkit-border-radius: 50%;
//  -moz-border-radius: 50%;
//  border-radius: 50%;

i {
  font-size: 30px;
  &:hover {
    cursor: pointer;
  }
}

div.panel-list {
  li {
    list-style-type: none;

    div.category_row {
      padding-top: 4px;
      padding-bottom: 4px;

      &:hover {
        background-color: #cdcdcd;
      }
    }

    span.category_name {
      padding-left: 20px;
    }

    span.category_buttons {
      float: right;

      .category_translations {
        padding-right: 10px;
      }

      a {
        margin-right: 5px;
      }
    }
  }
}

div.align_middle {
  text-align: center;
}

div.popover {
  button {
    margin-bottom: 5px;
  }
}

div.remove_button {
  background-color: #4B4B4B;
  color: red;
  font-size: 14px;
  display: block;
  width: 20px;
  height: 20px;
  text-align: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  position: relative;
  top: 10px;
  z-index: 9999;

  &:hover {
    background-color: #262626;
    cursor: pointer;
  }
}

label {
  span.required_field {
    color: red;
  }
}

ul#product_image_list {
  display: block;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  text-align: center;

  img {
    max-width: 200px;
  }

  li {
    list-style-type: none;
    display: inline-block;
    width: 25%;
    margin: 0;
    padding: 0;
  }
}

#image-upload-zone {
  background-color: #cdcdcd;
  margin-top: 40px;
  padding: 40px;
  font-size: 26px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}

div.spacer_between_components {
  display: block;
  width: 100%;
  height: 60px;
}

#add_translation_form_div {
  margin-bottom: 20px;
  display: none;
}

#toast-container > div {
  opacity: 1.0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}

.view_file {
  margin-top: 10px;
}

img.preview_image {
  max-width: 200px;
  margin-top: 10px;
  position: relative;
  top: -10px;
  margin-left: 10px;
}

a.view_file_button {
  position: relative;
  top: -10px;
  margin-left: 10px;
}

.dataTable {
  td {
    img {
      max-width: 200px;
    }
  }
}

.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #999
}

.tt-dropdown-menu {
  width: 422px;
  margin-top: 12px;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.tt-suggestion {
  padding: 3px 20px;
  font-size: 18px;
  line-height: 24px;
}

.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #0097cf;
}

.tt-suggestion p {
  margin: 0;
}

.custom_typeahead {
  font-size: 14px;
}

.loading_indicator {
  i {
    font-size: 40px;
  }
}

#product_typeahead_group {
  display: none;
}

.image_container {
  display: block;
  height: 225px;
  text-align: center;

  img {
    max-width: 275px;
    max-height: 225px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }
}

.list_container {

  p {
    font-size: 20px;
    text-align: center;
  }
}

div.col-lg-1 {
  span.icon.icon_children {
    zoom: 0.5;
    -moz-transform: scale(0.5);
    margin-left: -20px;
  }

  span.icon.icon_adults {
    zoom: 0.5;
    -moz-transform: scale(0.5);
    margin-left: -20px;
  }

  span.icon.icon_elderly {
    zoom: 0.5;
    -moz-transform: scale(0.5);
    margin-left: -20px;
  }

  span.icon.icon_children_white {
    zoom: 0.5;
    -moz-transform: scale(0.5);
    margin-left: -20px;
  }

  span.icon.icon_adults_white {
    zoom: 0.5;
    -moz-transform: scale(0.5);
    margin-left: -20px;
  }

  span.icon.icon_elderly_white {
    zoom: 0.5;
    -moz-transform: scale(0.5);
    margin-left: -20px;
  }
}

.icon_container {
  background-color: #cdcdcd;
  text-align: center;

  .icon_show_container {
    padding: 5px;
  }

  span {
    &:hover {
      cursor: pointer;
    }
  }

  i {
    &:hover {
      cursor: pointer;
    }
  }

  .row {
    margin: 0;
  }
  .fa {
    font-size: 20px;
  }
}

.choosen_icon_container {
  padding: 20px;
  background-color: #cdcdcd;
}

.image_list_item {
  background-color: #f8f8f8;
}

// Nieuwe huisstijl aanpassingen //

// Hoofdkopjes Website Webshop Modules etc
.nav > li > a:focus {
  background-color: #4dbce9 !important;
  color: #fff !important;
}

// Bijv subkopjes Pages Sitemap Static Components etc.
.sidebarsubs {
  background-color: #383133 !important;
  font-size: 16px !important;
}

// Icoontjes sidemenu
.navbar-default.navbar-static-side i {
  font-size: 20px;
}

// Hover over de Website > Pages > Menu Kopjes, kleur aanpassen
div.panel-list li div.category_row:hover {
  background-color: #eaf7fc;
}

// Gotowebsite button hover effect geven
a.btn.gotowebsite:hover {
  background-color: #2aafe5 !important;
}

.wysiwyg {
  display: block;
  width: 100%;
  position: relative;
}

.wysiwyg-toolbar {
  display: block;
  width: 100%;
}

