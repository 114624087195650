// bootstrap colors override
.btn-primary,
.btn-success {
  background-color: #56afff;
  border-color: #5795D8;
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.btn-success:active,
.btn-success:focus,
.btn-success:active:focus,
.btn-success:hover {
  background-color: #3F93DE;
  border-color: #3883C5;
}

.btn-primary:active:focus,
.btn-success:active:focus {
  background-color: #65B5FD;
  border-color: #56afff;
}

// font awesome
//fix for aligning fa-icons
.fa {
  //width: 22px;
}

.Fa--invisible {
  opacity: 0;
}

// table action columns
.action-column {
  width: 266px;
}

.input-group .form-control {
  z-index: auto;
}
