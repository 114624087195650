.Naked-cms__admin__main-content__sidebar {
  //z-index    : 0;
  //background : #333;

  @media (min-width : 768px) {
    //margin-top : 0;
    //margin-top : 100px;
  }
  //padding : 0;
  ul li {
    border-bottom : none;
    a {
      color : #fff;
      a:hover {
        background-color : #333333;
        color            : #fff;
      }
      a.active {
        background-color : @primaryColor;
      }
    }
    li a {
      display : block;
      padding : 10px;
    }
  }
}

.nav > li > a:hover, .nav > li > a:focus {
  //background : @primaryColor;
}

// base class correction - Naked-cms__admin__main-content__sidebar
//.Naked-cms__admin__main-content__sidebar {
//  padding : 0;
//
//  @media (min-width : 768px) {
//    padding-top : 0;
//    //margin-top  : 120px;
//    border-top  : 1px solid #ADADAD;
//  }
//}

.Naked-cms__admin__main-content__sidebar ul {
  padding-left : 0;
}


.Naked-cms__admin__main-content__sidebar ul li {
  list-style: none;
}
.Naked-cms__admin__main-content__sidebar ul li a,
.Naked-cms__admin__main-content__sidebar ul li li a {
  padding : 0 10px 0 30px;
}

.Naked-cms__admin__main-content__sidebar ul li a {
  height      : 50px;
  display     : flex;
  align-items : center;
}

.Naked-cms__admin__main-content__sidebar ul li a.active {
  background-color : @primaryColor;
}

.Naked-cms__admin__main-content__sidebar ul li a:hover {
  text-decoration : underline;
}

.Naked-cms__admin__main-content__sidebar ul li li {
  display     : flex;
  align-items : center;
  width       : 100%
}

.Naked-cms__admin__main-content__sidebar ul li li a {
  display     : flex;
  align-items : center;
  width       : 100%
}