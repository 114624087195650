//body.naked {
//  background      : linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("/img/loginheader.jpg");
//  background-size : cover;
//  .checkbox {
//    color : #fff;
//  }
//}

// set outer to height/width 100%
.html-Naked-cms--login,
body.Naked-cms--login {
  height : 100%;
  width  : 100%;
}

body.Naked-cms--login {
  //background            : linear-gradient(45deg, rgba(47, 46, 46, 0.89) 0%, rgba(42, 170, 254, 0.89) 100%), url("/img/loginheader.jpg");
  background            : linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("/img/loginheader.jpg");
  background-size       : cover;
  background-repeat     : no-repeat;
  background-attachment : fixed;
  height                : 100%;

  .socialmediabar {
    display    : block;
    width      : auto;
    float      : right;
    text-align : center;
    margin     : 15px 15px auto;
    right      : 15px;

    a{
      color: white;
    }
  }

  .text-center {
    position   : absolute;
    width      : 100%;
    height     : 28px;
    text-align : center;
    bottom     : 20px;
    left       : 0;
    color      : #fff;
    a {
      color  : #fff;
      border : none;
    }
  }

  .login-panel {
    background : transparent;
    .panel-heading {
      color            : #fff;
      background-color : transparent;
      border           : none;
    }
  }

  .panel-title {
    color       : #fff;
    text-align  : center;
    font-size   : 30px;
    font-weight : bold;
  }

  .checkbox {
    color : #fff;
  }

  ///!* Social media buttons *!/
  .btn-twitter, .btn-facebook, .btn-linkedin, .btn-google, .btn-instagram {
    background-color : rgb(42, 175, 229);
  }

  .btn-twitter:hover, .btn-facebook:hover, .btn-linkedin:hover, .btn-google:hover, .btn-instagram:hover {
    background-color : #4dbce9;
  }
}

.Naked-cms__login__logo {
  top      : 30px;
  left     : 30px;
  position : fixed;
}

.Naked-cms__login__panel__login {
  display         : flex;
  align-items     : center;
  justify-content : center;
  width           : 100%;
  height          : 100%;
}

.Naked-cms__login__panel__login__inner {
  width      : 425px;
  margin-top : 20px;
}

.Naked-cms__login__title--main {
  .font_family(@fontMain);
  font-size   : 48px;
  padding     : 0;
  margin      : 0 0 30px 0;
  color       : #fff;
  text-align  : center;
  line-height : 57px;
}

.Naked-cms__login__input--text {
  height    : 50px;
  font-size : 15px;
}

.Naked-cms__login__input--submit {
  .font_family(@fontMainMedium);
  font-size : 20px;
}

.Naked-cms__login__input__remember-me__label {
  color : #fff;
}

