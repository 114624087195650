// sizing
.Utils__height_full {
  height: 100%;
}
.Utils__width_full {
  width: 100%;
}

.Utils__horizontal-align--center {
  text-align : center;
}

// background positioning
.Utils__background-size--cover {
  background-size : cover;
}

// ----------- display/visibility -----------
.Utils__display--flex {
  display : flex;
}

.Utils__display--none {
  display : none;
}

.Utils__opacity--none {
  opacity : 0;
}

// ----------- flex positioning -----------
// flex align
// add on same class as 'display : flex;'
.Utils__flex__horizontal--center {
  justify-content : center;
}

// add on the object itself - parent is 'display : flex;' - multiple items
// add on the object itself - element has also 'display : flex;' - single item
.Utils__flex__vertical--center {
  align-items : center;
}

.Utils__flex__vertical--start {
  align-self : flex-start;
}

.Utils__flex__vertical--end {
  align-self : flex-end;
}

.Utils__flex_direction--row {
  flex-direction: row;
}
.Utils__flex_direction--column {
  flex-direction: column;
}

.Utils__display-flex--1 {
  flex : 1;
}

.Utils__display-flex--2 {
  flex : 2;
}

.Utils__display-flex--3 {
  flex : 3;
}

.Utils__display-flex--4 {
  flex : 4;
}

.Utils__display-flex--6 {
  flex : 6;
}

.Utils__display-flex--8 {
  flex : 8;
}

.Utils__display-flex--10 {
  flex : 10;
}

.Utils__display-flex--12 {
  flex : 12;
}

// flex wrap
.Utils__display-flex--wrap {
  flex-wrap: wrap;
}