.html-Naked-cms--admin,
body.Naked-cms--admin {
  min-height: 100%;
  width: 100%;
  display: flex;
}

// always show the scrollbar
.html-Naked-cms--admin {
  overflow-y: scroll;
}

body.Naked-cms--admin {
  padding-bottom: 0 !important;
}

body {
  .font_family(@fontMain);
}

a {
  color: @primaryColor;
}

.sidebar_menu_item_label {
  margin-left: 10px;
}

.document_list {
  li {
    list-style-type: none;
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 5px;

    &:hover{
      background-color: #f9f9f9;
    }
    a {
      font-size: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }
    a:hover {
      text-decoration: none;
    }
  }
}