// holder
.Naked-cms__admin__main-content {
  padding-top: 120px;
  display: flex;
  width: 100%;
}

// elements
.Naked-cms__admin__main-content__sidebar {
  width: 250px;
  min-height: 500px;
  background: #333;
  color: #fff;
  display: flex;
  flex-direction: column;
}

.Naked-cms__admin__main-content__sidebar__child-item {
  li {
    padding-left: 30px;
  }
}

.Naked-cms__admin__main-content__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px 30px;
}