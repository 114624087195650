// holder
.Naked-cms__admin__top-bar {
  height: 120px;
  background: #333;
  position: fixed;
  display: flex;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid #ADADAD;
}

// elements
.Naked-cms__admin__top-bar__sidebar {
  height: 120px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 250px;
  flex-direction: column;
  padding: 30px;
}

.Naked-cms__admin__top-bar__sidebar__link__img {
  width: 100%;
  display: block;
  max-width: 250px;
}

.Naked-cms__admin__top-bar__content {
  flex: 1;
  flex-direction: column;
  padding: 30px;
  border-left: 1px solid #ADADAD;
}

.Naked-cms__admin__top-bar__content__title {
  color: #fff;
  margin: 7px 0 0 0;
  padding: 0;
  font-size: 36px;
}

.Naked-cms__admin__top-bar__content__left {
  float: left;
}

.Naked-cms__admin__top-bar__content__right {
  float: right;
}

.Naked-cms__admin__top-bar__content__logout-button {
  margin-top: 20px;
  color: #fff;
  display: inline-block;
}

.Naked-cms__admin__top-bar__content__logout-button:hover {
  color: #fff;
}

.Naked-cms__admin__top-bar__content__logout-button--icon {
  font-size: 16px;
}